.classesTableContainer {
  position: relative;
}

table.classesTable {
  background: #fff;
  border-radius: 10px;
  table-layout: fixed;
  width: 100%;

  /* cell padding */
  --tb-pad: 12px; /* top-bottom */
  --lr-pad: 22px; /* left-right */

  padding-bottom: 11px;
}

table.classesTable th {
  font-family: var(--heading-font);
  font-weight: 700;
}

table.classesTable .period {
  width: 60px;
  /* padding order: top, right, bottom, left */
  text-align: left;
  padding: var(--tb-pad) 0px var(--tb-pad) var(--lr-pad);

  font-family: var(--heading-font);
  font-weight: 700;
}

table.classesTable .className {
  text-align: center;
  padding: var(--tb-pad) var(--lr-pad) var(--tb-pad) 0px;
}

table.classesTable .roomNumber {
  width: 120px;
  text-align: center;
  padding: var(--tb-pad) var(--lr-pad) var(--tb-pad) 0px;
}

table.classesTable .zoomLink {
  text-align: center;
  padding: var(--tb-pad) var(--lr-pad) var(--tb-pad) 0px;
}

table.classesTable .classColor {
  width: 100px;
  text-align: center;
  padding: var(--tb-pad) var(--lr-pad) var(--tb-pad) 0px;
}

table.classesTable input {
  width: calc(100% - 20px);
  height: 25px;
  font-family: var(--default-font);

  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  border-radius: 5px;
  border: 1px solid #777;

  padding: 5px 10px 5px 10px;
}

.saveButton {
  -webkit-appearance: none;

  width: 100%;
  height: 50px;

  padding: 5px 10px 5px 10px;
  margin-top: 15px;

  background: var(--main-theme-color);
  color: #fff;

  /* border: 2px solid var(--main-theme-color); */
  border: none;
  border-radius: 5px;

  cursor: pointer;

  font-family: var(--heading-font);
  font-size: 20px;
  font-weight: 700;
}
