.loginContainer {
  margin: 0;
  /* padding: var(--app-padding); */
  position: relative;
  background-color: var(--main-theme-color);
}

.loginContainer .centeredContainer {
  position: absolute;
  top: 50%;
  left: 50%;

  padding: var(--app-padding);

  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  width: calc(100% - (2 * var(--app-padding)));
  max-width: 320px;
}

.loginContainer .appName {
  margin: 0px;
  padding: 0px;

  color: #fff;
  text-align: center;

  font-family: var(--heading-font);
  font-size: 48px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.loginContainer .logInButton {
  margin-top: 15px;

  width: 100%;
  height: 50px;

  cursor: pointer;

  text-align: center;
  font-size: 18px;
  font-family: var(--default-font);
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  color: #fff;
  background: var(--main-theme-color);

  border-radius: 4px;
  border: 2px solid #fff;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;

  transition: all 200ms ease;
}

.loginContainer .logInButton:hover,
.loginContainer .logInButton.loading {
  background: #fff;
  color: var(--main-theme-color);
  /* border: 2px solid var(--main-theme-color); */
}

.loginContainer .logInButton.loading {
  pointer-events: none;
}

.loginContainer .logInButton:active {
  opacity: 0.85;
}

.loginContainer .logInButton:not(.loading):focus {
  border: 2px solid #6ca8e0;
}

.loginContainer .logInButton.invisible {
  height: 0px;
  margin: 0px;
  opacity: 0;
}
