.loadingSpinner {
  /* width: 75px;
  height: 75px; */

  background: transparent;
  /* border: 3px solid #000; */
  /* border-top: 3px solid transparent; */
  border-radius: 50%;

  animation: spinneranim 550ms linear infinite;
}

@keyframes spinneranim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
