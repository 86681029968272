.loadingPlaceholderScreen {
  margin: 0px;
  padding: 0px;
  position: relative;
}

.loadingPlaceholderScreen .centeredArea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .loadingPlaceholderScreen .centeredArea .centeredAreaInner {
  position: relative;
  width: 100%;
  height: 100%;
} */

.loadingPlaceholderScreen .centeredArea .spinnerHolder {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}
