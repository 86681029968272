.navBar {
  /* stuff */
  width: 100%;
  height: calc(env(safe-area-inset-top, 0px) + var(--navbar-height));
  /* height: 78px; */
  /* position: fixed; */
  position: sticky;
  color: #fff;
  /* background: var(--main-theme-color); */
  top: 0;
  left: 0;
  z-index: 2;
  transition: box-shadow 200ms ease;
}

.navBar:not(.navBarSmall) .sideBarButton {
  display: none; /* for normal */
}

.navBar.navBarScrolled {
  -webkit-box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.37);
}

.navBar .navBarInner {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateY(calc(env(safe-area-inset-top, 0px) / 2));
}

.navBarTitle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.navBarTitle span {
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #fff;
  display: inline-block;
  left: var(--app-padding);

  font-size: 32px;
  font-family: var(--heading-font);

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.navBarButtons {
  position: absolute;
  top: 50%;
  right: var(--app-padding);
  transform: translateY(-50%);
}

ul.navBarButtonsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navBarItem {
  /* stuff */
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
}

.navBarItem:last-child {
  margin-right: 0px;
}

.navBarItem a {
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;

  border-radius: 50%;

  width: 1em;
  height: 1em;
  position: relative;

  padding: 5px;

  display: inline-block;

  transition: color 200ms cubic-bezier(0.12, 0.75, 0.71, 0.93),
    background-color 200ms cubic-bezier(0.12, 0.75, 0.71, 0.93);
}

.navBarItem:hover a,
.navBarItem.itemActive a {
  background-color: #fff;
  color: var(--main-theme-color);
}

.navBarItem a i,
.sideBarButton i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* SMALL NAVBAR (<700px) */
.navBar.navBarSmall .navBarTitle {
  left: 50%;
  transform: translate(-50%, -50%);
}
.navBar.navBarSmall .navBarTitle span {
  left: 0px;
}
.navBar.navBarSmall .navBarButtons {
  display: none;
}

/* sidebar button */
.navBar .sideBarButton {
  width: 1.5em;
  height: 1.5em;

  padding: 10px;

  position: relative;
  top: 50%;
  left: var(--app-padding);
  transform: translateY(-50%);

  font-size: 30px;

  display: inline-block;
  background: transparent;

  /* background: #f00; */
  color: #fff;

  border: none;
  border-radius: 50%;
  -webkit-appearance: none;

  cursor: pointer;
  user-select: none;
}

.navTooltip {
  font-family: var(--default-font);
}
