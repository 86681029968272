footer.appFooter {
  width: 100%;
  /* height: 85px; */
  height: var(--footer-height);
  background-color: var(--main-theme-color);
  color: #fff;
  position: relative;
  font-family: var(--default-font);
}

footer.appFooter .footerContent {
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

footer.appFooter a {
  color: #6fb6f7;
  font-weight: 700;
}
